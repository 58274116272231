import React from "react";
import TrustedPartners from "../../components/TrustedPartners";
import Footer from "../../components/Footer";
import ImpactComponent from "../../components/home/impactcomponent";
import ai3d from '../../assets/home/ai3d.svg'
import Whatwe from "../../components/home/whatwe";
import Events from "../../components/home/events";
import News from "../../components/home/news";
import CustTestimonials from "../../components/home/testimonials";

const HomePage = () => {
  const buttonList = [
    "I want to see use cases",
    "Can you show me value drivers?",
    "How do you reduce hallucinations?",
    "What you do?",
  ];
  return (
    <div className="">
      <div className=" bg-[#03111a]">
        <ImpactComponent />
      </div>
      <div className="flex justify-center overflow-hidden" style={{ height: 'calc(100% - 200px)' }}>
        <img src={ai3d} alt="arhasi 3d logo" className="object-cover" style={{ height: 'calc(100% - 200px)' }} />
      </div>


      <Whatwe />
      <Events />
      <News />
      <CustTestimonials />

      <div className="max-w-[1360px] mx-auto">
        <div className="desktop:mx-[112px] tablet:mx-[48px] mx-[24px] mt-28 desktop:mt-20">
          {/* <BlogpostCards /> */}
          <TrustedPartners />

        </div>

        <div className="">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
