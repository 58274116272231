import React from "react";
import ai from '../../assets/home/flower.svg'
import rightarrow from '../../assets/home/rightarrow.svg'

export default function GlassCardGlobal() {
  const gradientBackgroundStyle = {
    backgroundImage: "conic-gradient(#000000, #6d4fa5)",
    filter: "blur(40px)",
  };

  return (
    <div className="flex justify-center items-center sm:w-[98%] lg:w-[49%]">
      {/* Gradient Background Container */}
      <div className="relative">
        <div
          className="absolute top-[30px] left-[26%] right-0 bottom-0 bg-[#ff0000] z-0 opacity-60 h-36"
          style={gradientBackgroundStyle}
        ></div>
        {/* Glass effect card */}
        <div className="relative backdrop-blur-lg bg-white/5 border border-white/20 rounded-lg p-8 z-10">
          <div className=" border-b-2 border-[#3e484f] flex justify-between align-middle items-center  py-2">
            <div className=" flex flex-row items-center gap-2">
              <div className=" p-[4px] border-gray-400 border-[1px] rounded-lg">
                <img src={ai} alt="" />
              </div>
              <div className=" text-[16px] font-semibold">
                GlobeNewswire
              </div>
            </div>
            <div className="">
              <img src={rightarrow} alt="" />
            </div>
          </div>
          <div className="border-b-2 border-[#3e484f] py-2 text-[17px] font-semibold sm:text-[14px] lg:text-[16px]">
            This service focuses on AI chatbot security, ensuring secure and compliant conversational AI interactions          </div>
          <div className=" flex flex-row gap-4  py-2">
            <div className="">
              <div className=" text-[14px] text-[#b7bcbe] font-semibold">
                Categories
              </div>
              <div className="text-[16px] font-semibold">
                Products
              </div>
            </div>
            <div className="">
              <div className="text-[14px] text-[#b7bcbe] font-semibold">
                Published on
              </div>
              <div className="">
                Apr 23, 2024              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
