import React from "react";

import blub from "../../assets/home/bulb.svg";
import handshake from "../../assets/home/handshake.svg";
import users from "../../assets/home/users.svg";
import robo from "../../assets/home/robo.svg";
import GlassCard from "./glass";
import GlassCardCMS from "./glasscms";
import GlassCardEIN from "./glassein";
import GlassCardGlobal from "./glassglobal";
import reddot from '../../assets/home/reddot.svg'

export default function Events() {
    const values = [
        {
            value: "Value 1 / Innovation First",
            desc: "As AI pioneers, we push boundaries to deliver cutting-edge solutions that keep you ahead.",
            img: blub,
        },
        {
            value: "Value 2 / Trust & Transparency",
            desc: "Our Transparent Solutions Provide Accurate, Actionable Data You Can Trust.",
            img: handshake,
        },
        {
            value: "Value 3 / Customer-Centric Approach",
            desc: "Your Success Drives Us. Our Solutions Are Tailored For Optimal Results And Ease.",
            img: users,
        },
        {
            value: "Value 4 / Future-Ready Solutions",
            desc: "We Solve Today's Challenges And Prepare You For Tomorrow's Evolving World.",
            img: robo,
        },
    ];

    return (
        <div className="px-4 sm:px-10 lg:px-20 py-10">
            <div className=" flex justify-center">
                <div className=" w-[80%]  flex-col">
                    {/* Title Section */}
                    <div className="flex justify-center">
                        <div className="relative w-[22.5%]">
                            <div className="bg-gradient-to-r from-[#5b4a59] via-[#b9adb6] to-[#5b4a59] p-[1.5px] rounded-full">
                                <div className="bg-[#262532] flex flex-row py-[4px] sm:py-2 px-4 sm:px-2 rounded-full font-bold text-white text-[12px] sm:text-[14px] text-center">
                                    <img src={reddot} alt="" />
                                    Upcoming & Past Events
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">

                        <div className="font-semibold text-3xl sm:text-5xl lg:text-[60px] text-white py-10 text-center lg:text-left">
                            Innovating Together with AI{" "}
                        </div>
                    </div>
                    <div className=" flex justify-center">

                        <div className="font-semibold text-[14px] sm:text-[16px] text-[#b3b7ba] lg:w-[46%] text-center">

                            Discover how Arhasi.AI is driving change through knowledge-sharing
                            and collaboration.{" "}
                        </div>
                    </div>
                </div>
            </div>

            {/* Values Section */}
            <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 flex-wrap mt-10">
                <GlassCard />
                <GlassCardCMS />
                <GlassCardEIN />
                <GlassCardGlobal />
            </div>
        </div>
    );
}
