import React from "react";
import yourImage from "../../assets/home/landingbg.svg";
import reddot from '../../assets/home/reddot.svg'

const ImpactComponent = () => {
  return (
    <div
      className="relative flex flex-col items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${yourImage})` }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content wrapper */}
      <div className="relative flex w-[90%] sm:w-[80%] lg:w-[70%] flex-col justify-center text-center">

        {/* Arhasi.AI badge */}
        <div className="mb-4 flex justify-center">

          <div className="relative inline-block">
            <div className="bg-gradient-to-r from-[#5b4a59] via-[#b9adb6] to-[#5b4a59] p-[1.5px] rounded-full">
              <div className="bg-[#382739] flex flex-row py-[4px] sm:py-2 px-4 sm:px-2 sm:pr-4 rounded-full font-bold text-white text-[12px] sm:text-[14px] text-center">
                <img src={reddot} alt="" />
                Arhasi AI
              </div>
            </div>
          </div>
        </div>

        {/* Main title */}
        <div className="text-white text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-semibold z-10">
          AI Solutions that Drive Real-World Impact
        </div>

        {/* Subtitle */}
        <div className="text-[#b4b8bc] text-[14px] sm:text-[16px] my-4 sm:my-8 flex justify-center">
          <div className="w-full sm:w-[80%] md:w-[70%]">
            Arhasi transforms challenges into opportunities with advanced AI, helping your business make smarter decisions and stay future-ready.
          </div>
        </div>

        {/* Button */}
        <div className="flex justify-center mt-4 sm:mt-8">
          <div className="relative inline-block">
            <div className="bg-gradient-to-r from-[#ef6868] via-[#f8bfbf] to-[#ef6868] p-[2px] rounded-full">
              <div className="bg-[#e81a1b] py-2 sm:py-3 px-4 sm:px-6 rounded-full font-bold text-white text-[12px] sm:text-[14px] text-center">
                Explore AI Solution
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactComponent;
