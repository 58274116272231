export const handleFormSubmit = (e, nameRef, emailRef, messageRef) => {
  e.preventDefault();

  const name = nameRef?.current?.value;
  const email = emailRef?.current?.value;
  const message = messageRef?.current?.value;

  console.log(name);
  console.log(email);
  console.log(message);

  // Validate name (e.g., must not be empty and should be at least 2 characters)
  const validateName = (name) => {
    return name && name.trim().length >= 2;
  };

  // Validate message (e.g., must not be empty)
  const validateMessage = (message) => {
    return message && message.trim().length > 3;
  };

  // Validate email (check for dot, @ & atleast some characters after @)
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // Check all validations
  if (!validateName(name)) {
    alert("Please enter a valid name (at least 2 characters).");
    return;
  }

  if (!validateEmail(email)) {
    alert("Please enter a valid email address.");
    return;
  }

  if (!validateMessage(message)) {
    alert("Please enter a message.");
    return;
  }

  const data = {
    name: name,
    email: email,
    message: message,
  };

  console.log(data);

  try {
    const postData = async () => {
      console.log("posting data...");

      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-KEY": `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Response: ", responseData);
        //reset the user inputs
        nameRef.current.value = "";
        emailRef.current.value = "";
        messageRef.current.value = "";
        alert("Message sent successfully!");
      } else if (response.status === 422) {
        const errorData = await response.json();
        console.error("Validation Error:", errorData);
        alert("Validation error: Please check your input.");
      } else {
        throw new Error("Network response was not ok");
      }
    };

    postData();
  } catch (error) {
    console.error("Error sending message:", error);
    alert("An error occurred. Please try again.");
  }
};
