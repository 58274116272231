import React from "react";
import { Link, useNavigate } from "react-router-dom";
import TrustedPartners from "./TrustedPartners";
import fotterbg from '../assets/fotterbg.svg'
import logo from '../assets/arhasi.png'
import downarrow from '../assets/downarrow.svg'

import facebook from '../assets/facebook.svg'
import X from '../assets/x.svg'
import us from '../assets/us.svg'
import linkedin from '../assets/linkedin.svg'

const Footer = () => {

  const navigate = useNavigate()

  const links = [
    {
      name: 'Company',
      url: '/'
    },
    {
      name: 'About Us',
      url: '/about-us'
    }, {
      name: 'Our Customers',
      url: '/our-customers'
    }, {
      name: 'Products',
      url: '/products'
    }, {
      name: 'Services',
      url: '/services'
    }, {
      name: 'AI with Integrity',
      url: '/ai-with-integrity'
    },
  ]

  const handleLinksClick = (link) => {
    navigate(link)
    window.scrollTo(0, 0);
  }

  return (
    //  className="px-4 sm:px-10 lg:px-20 py-10 bg-cover bg-center"
    // style = {{ backgroundImage: `url(${newsbg})` }

    <div className=" bg-cover bg-center md:h-[100vh]" style={{ backgroundImage: `url(${fotterbg})` }}>
      <div className=" flex flex-col justify-end h-full items-end px-[7%]">
        <div className=" flex flex-col  md:flex-row justify-between w-full">

          <div className=" flex flex-col gap-2 w-full sm:w-full md:w-[64%]">
            <div className=" w-[14%] ml-[-5px]">

              <div className=" w-[100%] h-[100%]">
                <img src={logo} alt="arhasi logo" />
              </div>
            </div>
            <div className="text-p-md font-semibold">
              Stay updated on AI news and Arhasi.AI events.
            </div>
            <div className=" flex flex-row items-center align-middle gap-2 mt-4">
              <div className=" w-[60%]">
                <input className="border-2 border-[#8c8f93] rounded-full px-3 py-[10px] w-full bg-transparent" type="text" placeholder="Enter your Email" />
              </div>
              <div className="flex justify-center">
                <div className="relative inline-block">
                  <div className="bg-gradient-to-r from-[#ef6868] via-[#f8bfbf] to-[#ef6868] p-[2px] rounded-full">
                    <div className="bg-[#e81a1b] px-4 py-3 sm:py-3 sm:px-6 rounded-full text-white text-[14px] font-semibold sm:text-[14px] text-center">
                      Subscribe
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" text-[#bbb9bc] text-p-sm w-[100%] sm:w-[100%] md:w-[70%] mt-4">
              By subscribing you agree to with our Privacy Policy and provide consent to receive updates from our company
            </div>
          </div>

          <div className=" flex flex-col gap-2 w-full sm:w-full md:w-[22%] justify-between">
            {/* <div className="text-[#b6b7ba] text-p-sm font-semibold">
              Company
            </div> */}
            {
              links.map((link) => {
                return (
                  <div className=" text-[#b6b7ba] text-p-sm font-semibold cursor-pointer" onClick={() => handleLinksClick(link.url)}>
                    {link.name}
                  </div>
                )
              })
            }
          </div>

          <div className=" flex flex-col gap-2 w-full sm:w-full md:w-[22%]">
            <div className=" text-p-md font-semibold">
              Social Media
            </div>
            <div className=" flex flex-row gap-2">
              <div className=" bg-[#232730] border-[1px] border-[#45484f] p-2 rounded-lg">
                <Link>

                  <img src={facebook} alt="fackbook" />
                </Link>
              </div>
              <div className=" bg-[#232730] border-[1px] border-[#45484f] p-2 rounded-lg">
                <Link
                  to={"https://www.linkedin.com/company/arhasi/posts/?feedView=all"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="linkedin" />
                </Link>
              </div>
              <div className=" bg-[#232730] border-[1px] border-[#45484f] p-2 rounded-lg">
                <Link
                  to={"https://x.com/aiwithintegrity"}
                  target="_blank"
                  rel="noopener noreferrer"
                >

                  <img src={X} alt="x" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className=" w-full h-[1px] rounded bg-[#5a5459]  my-12"></div>

        <div className="flex flex-col sm:flex-row justify-between items-center w-full mb-12">
          <div className="flex flex-col sm:flex-row w-full sm:w-[50%] justify-between items-center gap-4 sm:gap-0">
            <div className="bg-[#1c2830] border-2 border-[#3e484f] rounded-full flex items-center px-3 py-2">
              <img src={us} alt="us logo" className="mr-2 w-6 h-6" />
              United States
              <img src={downarrow} alt="down arrow" className="ml-2" />
            </div>
            <div className="text-p-sm font-semibold text-[#b3b7ba]">
              Privacy Policy
            </div>
            <div className="text-p-sm font-semibold text-[#b3b7ba]">
              Terms of Service
            </div>
            <div className="text-p-sm font-semibold text-[#b3b7ba]">
              Cookie Settings
            </div>
          </div>
          <div className="w-full sm:w-[30%] flex justify-center sm:justify-end text-p-sm font-semibold text-[#b3b7ba] mt-4 sm:mt-0">
            © 2024 Arhasi, Inc.
          </div>
        </div>


      </div>
    </div >
  );
};

export default Footer;
