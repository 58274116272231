import React from "react";

const TrustedPartners = ({ from }) => {
  const partnerLogos = [
    {
      name: "alteryx",
      // logo: (
      //   <svg
      //     class="alteryx-logo"
      //     width="145"
      //     height="45"
      //     viewBox="0 0 145 45"
      //     fill="none"
      //     xmlns="http://www.w3.org/2000/svg"
      //   >
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M13.9584 29.677L13.8764 29.738L13.7894 29.791C12.2584 30.849 9.97344 31.556 8.09844 31.556H8.09544C5.34744 31.497 4.34644 30.525 4.29144 27.879C4.35044 24.832 5.37044 23.893 8.80244 23.676L13.5404 23.454L13.7524 23.445L13.9584 23.439V29.677ZM22.5864 31.178C22.4344 30.664 22.0044 30.35 21.4894 30.35C21.3504 30.357 21.2224 30.365 21.0564 30.404C20.3214 30.58 19.9134 30.635 19.3504 30.673C18.2784 30.726 18.0884 30.307 18.0184 29.102V12.611C17.9864 12.23 17.7874 11.849 17.4934 11.619L17.3864 11.55C17.3524 11.523 17.3174 11.488 17.2724 11.465C16.3774 10.939 14.8884 10.515 13.6234 10.195L13.5294 10.168C12.0284 9.80498 10.3674 9.61298 8.72544 9.61298C7.76044 9.61298 6.88944 9.68698 6.54044 9.72998C5.81944 9.79998 3.87144 10.125 3.21744 10.348C3.02744 10.41 2.41244 10.702 2.39144 11.281C2.40244 11.582 2.74244 12.547 2.74244 12.547C2.96344 13.222 3.19844 13.386 3.62444 13.473C3.96744 13.512 4.70644 13.357 5.19944 13.272C6.46944 13.037 7.29944 12.948 7.89544 12.941C8.66444 12.93 9.63044 12.919 10.7644 13.083C12.9184 13.414 13.7484 14.075 13.7484 14.075V20.479L13.5584 20.486L8.95844 20.601C2.80644 20.795 -0.0405636 23.235 0.000436399 28.153C-0.0085636 30.386 0.691437 32.177 2.02444 33.335C3.26244 34.423 5.07844 34.996 7.26344 34.996H7.27244C9.78144 34.996 11.9394 34.223 14.0424 32.558L14.2424 32.404L14.3454 32.635C14.8514 33.75 15.7144 34.471 16.8984 34.773C17.4294 34.92 18.0364 34.996 18.8114 34.996C19.0104 34.996 19.2104 34.984 19.3894 34.964C20.0914 34.868 21.0244 34.74 22.0644 34.25C22.6344 33.979 23.0384 33.643 23.0084 33.041C22.9854 32.51 22.5864 31.178 22.5864 31.178Z"
      //       fill="#0078C0"
      //     ></path>
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M90.5792 34.887H88.3412C87.9972 34.887 87.7092 34.773 87.5312 34.576C87.3512 34.366 87.2712 34.081 87.2712 33.693V12.503C87.2552 12.105 87.3582 11.815 87.5612 11.621C87.7392 11.441 88.0032 11.363 88.3592 11.363H90.3822C90.7592 11.363 91.0142 11.436 91.2122 11.603C91.4152 11.774 91.5232 12.054 91.5162 12.392L91.5392 12.953L91.5602 13.43L91.8762 13.083C92.8252 12.061 94.4522 10.841 96.7942 10.841C97.1252 10.841 97.7002 10.891 98.2042 10.984C98.6242 11.062 98.7832 11.488 98.7532 11.71C98.7532 11.824 98.7492 11.911 98.7352 11.99L98.4022 14.128C98.3792 14.499 98.0382 14.774 97.7522 14.774C97.4922 14.764 97.3462 14.743 97.1822 14.714C96.9382 14.677 96.6642 14.625 96.1812 14.625C94.6182 14.625 92.8712 15.662 91.7052 17.271L91.6662 17.318V33.693C91.6662 34.059 91.5782 34.346 91.3962 34.553C91.2172 34.763 90.9252 34.887 90.5792 34.887Z"
      //       fill="#0078C0"
      //     ></path>
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M76.7729 20.546H66.4979C66.6389 15.361 68.6169 13.242 71.8669 13.242C75.0309 13.242 76.8209 14.979 76.8209 19.043C76.8209 19.362 76.8209 20.17 76.7729 20.546ZM71.9629 9.94C66.2119 9.94 61.8789 13.942 61.8789 23.044C61.8789 31.388 65.7889 35.486 73.1419 35.486C74.3059 35.486 75.6899 35.35 77.0129 35.08C77.2199 35.041 77.4329 34.984 77.6399 34.939C78.4749 34.708 79.3189 34.361 79.6289 34.216C80.0899 34.04 80.4459 33.685 80.4729 33.239C80.3479 32.575 80.1789 31.899 79.9849 31.246C79.8089 30.808 79.4119 30.532 79.0039 30.557C78.8649 30.557 76.8879 30.986 76.7729 31.021C75.6399 31.313 74.3959 31.483 73.2359 31.483C68.0049 31.483 66.4479 28.983 66.4479 23.842V23.464H80.1489C80.5549 23.427 80.8699 23.119 80.9269 22.713C80.9269 22.658 80.9319 22.597 80.9409 22.535C80.9859 21.636 81.0159 20.692 81.0159 19.93C81.0159 13.614 77.9479 9.94 71.9629 9.94Z"
      //       fill="#0078C0"
      //     ></path>
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M104.394 44.428H104.246C103.438 44.428 102.768 44.37 102.116 44.24C101.714 44.17 101.566 43.648 101.645 42.934C101.645 42.934 101.694 42.172 101.808 41.634C101.915 41.086 102.175 40.837 102.464 40.837H102.546L102.768 40.847C102.9 40.856 103.087 40.881 103.261 40.895C103.66 40.938 104.066 40.981 104.383 40.981C107.054 40.951 108.495 39.975 109.517 37.5L110.749 34.492L110.865 34.173L110.909 34.04L110.865 33.897L102.638 12.141C102.602 12.045 102.59 11.947 102.59 11.858C102.59 11.774 102.602 11.619 102.715 11.488C102.8 11.397 102.946 11.331 103.105 11.331H106.224C106.677 11.331 107.015 11.57 107.115 11.92L112.532 27.505L112.893 28.548L113.265 27.505L118.865 12.036C118.983 11.603 119.334 11.324 119.743 11.324H119.766L119.85 11.331H122.437C122.646 11.331 122.801 11.372 122.909 11.459C123.016 11.55 123.059 11.689 123.052 11.792C123.052 11.941 123.016 12.066 122.972 12.187L113.769 36.882C111.764 42.384 109.223 44.428 104.394 44.428Z"
      //       fill="#0078C0"
      //     ></path>
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M141.487 34.827C140.951 34.827 140.508 34.576 140.257 34.115L135.34 26.524L135.166 26.27L135 26.524L130.089 34.108C129.829 34.576 129.386 34.827 128.844 34.827L128.764 34.817H126.136C125.921 34.817 125.767 34.766 125.636 34.67C125.507 34.563 125.416 34.375 125.431 34.197C125.431 33.979 125.507 33.748 125.636 33.559L132.751 22.763L132.833 22.653L132.751 22.542L126.195 12.438C126.079 12.25 126.022 12.091 126.026 11.941C126.026 11.516 126.416 11.26 126.789 11.256H129.256C129.788 11.256 130.205 11.486 130.388 11.849L135.004 18.852L135.166 19.093L135.324 18.852L139.915 11.892C140.12 11.488 140.542 11.256 141.023 11.256H143.551C143.916 11.256 144.315 11.516 144.315 11.938C144.315 12.091 144.258 12.25 144.142 12.42L137.586 22.542L137.509 22.653L137.586 22.763L144.68 33.536C144.833 33.748 144.908 33.97 144.908 34.187C144.922 34.375 144.835 34.563 144.709 34.67C144.575 34.766 144.418 34.817 144.196 34.817H141.585L141.487 34.827Z"
      //       fill="#0078C0"
      //     ></path>
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M34.9622 34.889C33.8012 34.889 32.6822 34.702 31.7102 34.348C30.8202 34.038 30.1152 33.593 29.6142 33.041C28.2162 31.504 28.0932 29.59 28.0742 27.062V2.915C28.0742 2.773 28.1362 2.629 28.2272 2.522C29.0552 1.596 30.1252 0.905002 31.4092 0.469002C31.4892 0.440002 31.5432 0.440002 31.6072 0.440002C31.7102 0.440002 31.9812 0.458002 32.2032 0.674002C32.4012 0.880002 32.4952 1.16 32.4922 1.528V27.216C32.5012 28.553 32.8212 29.615 33.4092 30.278C33.9112 30.831 34.7232 31.111 35.7562 31.111C36.0072 31.111 36.2902 31.091 36.6282 31.05C37.3802 30.942 38.1042 30.731 38.5802 30.58C38.7102 30.525 38.8272 30.5 38.9652 30.5C39.3742 30.5 39.7292 30.79 39.8672 31.219C39.8672 31.219 40.2312 32.452 40.2772 33.034C40.3072 33.536 39.8572 33.874 39.4422 34.02C38.9142 34.232 37.0472 34.889 34.9622 34.889Z"
      //       fill="#0078C0"
      //     ></path>
      //     <path
      //       fill-rule="evenodd"
      //       clip-rule="evenodd"
      //       d="M57.5236 31.205C57.3876 30.774 57.0316 30.486 56.6116 30.486C56.4816 30.486 56.3586 30.512 56.2306 30.562C55.5376 30.785 54.4926 31.072 53.5096 31.091L53.4046 31.107C52.3446 31.107 51.5476 30.817 51.0396 30.258C50.5606 29.732 50.2166 28.876 50.1886 27.273C50.1886 27.238 50.1886 27.213 50.1886 27.184L50.3216 14.468H55.5236C55.8016 14.468 56.0326 14.436 56.2476 14.272C56.5066 14.068 56.5476 13.733 56.5416 13.548V11.947C56.5476 11.603 56.4456 11.354 56.2516 11.196C56.0206 11.03 55.7656 11.016 55.5696 11.016H50.3216V3.09899C50.3216 2.72499 50.2296 2.45099 50.0356 2.24799C49.8196 2.03599 49.5386 2.01099 49.4406 2.01099C49.3746 2.01099 49.3176 2.01099 49.2196 2.04099C48.9156 2.15199 48.0976 2.43799 47.2026 3.06699C46.8926 3.29299 46.5776 3.57399 46.2616 3.89299C46.1736 4.00299 46.1236 4.13899 46.1236 4.27399L45.9986 10.934H43.3116C43.0496 10.934 42.7646 11.001 42.5606 11.215C42.3586 11.436 42.3086 11.71 42.3086 11.99V13.452C42.3126 13.767 42.2986 14.041 42.5426 14.308C42.8186 14.562 43.0886 14.54 43.4006 14.552H45.9256L45.6856 26.513L45.6906 27.026C45.6996 29.561 45.8226 31.483 47.2256 33.036C48.3046 34.207 50.2666 34.88 52.5996 34.889C54.7096 34.889 56.3496 34.329 57.0996 34.02C57.5006 33.851 57.9536 33.526 57.9346 33.025C57.9346 32.548 57.5236 31.205 57.5236 31.205Z"
      //       fill="#0078C0"
      //     ></path>
      //   </svg>
      // ),
      logo: "./alteryx.png",
    },
    {
      name: "redis",
      logo: "https://upload.wikimedia.org/wikipedia/fr/6/6b/Redis_Logo.svg",
    },
    {
      name: "alpha transform holdings",
      logo: "https://static.wixstatic.com/media/a185af_8d28ab98986b490bb9570a8fba34a3d7~mv2.png/v1/fill/w_127,h_94,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a185af_8d28ab98986b490bb9570a8fba34a3d7~mv2.png",
    },
    {
      name: "azure",
      logo: "https://miro.medium.com/v2/resize:fit:1400/0*MCsGhkvp8vwPqKFH.png",
    },
    {
      name: "hp",
      logo: "./download-removebg-preview.png",
    },
    {
      name: "databricks",
      logo: "./Databricks-Emblem-removebg-preview.png",
    },
    {
      name: "snowflake",
      logo: "https://signup.snowflake.com/static/images/logo-sno-blue.svg",
    },
    {
      name: "gts",
      logo: "https://gts-ts.com/wp-content/uploads/2024/01/180x70.png",
    },
    {
      name: "googlecloud",
      logo: "./images-removebg-preview.png",
    },
  ];
  return (
    <div className="flex desktop:flex-row flex-col justify-center items-center desktop:gap-24 tablet:gap-12 gap-8 py-8">
      <div
        className={`${
          from === "productsPage" ? "hidden" : "flex"
        } flex-col desktop:text-left tablet:text-left text-center`}
      >
        <h1 className="text-p-md text-textPrimary">Trusted by our partners,</h1>
        <p className="desktop:text-nowrap text-p-md text-textSecondary">
          Empowering the top companies in reliable & secure AI
        </p>
      </div>
      <div className="relative w-full image-scroll-container">
        <div className="image-list">
          {/* <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/ZKRq1z235oPmkciGkoimdiqigTg.svg"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/ctz7jfVgOlhPnJtoWX5OM4xFo8.png?scale-down-to=1024"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/itv6f8fuUGhsXcn88TAbhBfy0w.png"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/93vyEbNvGwW6tAXvemSFT7k.png"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/FFS2rnhpRLrDFcrnoLfon5fM2yc.webp?scale-down-to=512"
            />
          </div> */}
          {partnerLogos.map((partner) => {
            return (
              <div className="image-item">
                <img className="image" alt="" src={partner.logo} />
              </div>
            );
          })}
        </div>
        {/* <!-- Duplicated images for continuous effect --> */}
        <div className="image-list">
          {/* <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/ZKRq1z235oPmkciGkoimdiqigTg.svg"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/ctz7jfVgOlhPnJtoWX5OM4xFo8.png?scale-down-to=1024"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/itv6f8fuUGhsXcn88TAbhBfy0w.png"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/93vyEbNvGwW6tAXvemSFT7k.png"
            />
          </div>
          <div className="image-item">
            <img
              className="image"
              alt=""
              src="https://framerusercontent.com/images/FFS2rnhpRLrDFcrnoLfon5fM2yc.webp?scale-down-to=512"
            />
          </div> */}
          {partnerLogos.map((partner) => {
            return (
              <div className="image-item">
                <img className="image" alt="" src={partner.logo} />
              </div>
            );
          })}
        </div>

        <div className="absolute z-10 left-0 top-0 bottom-0 w-[10%] h-full bg-gradient-to-r from-background to-transparent" />
        <div className="absolute z-10 right-0 top-0 bottom-0 w-[10%] h-full bg-gradient-to-l from-background to-transparent" />
      </div>
    </div>
  );
};

export default TrustedPartners;
