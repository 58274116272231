import React from 'react';
import blub from '../../assets/home/bulb.svg';
import handshake from '../../assets/home/handshake.svg';
import users from '../../assets/home/users.svg';
import robo from '../../assets/home/robo.svg';

import reddot from '../../assets/home/reddot.svg'


export default function Whatwe() {

    const values = [
        {
            value: "Value 1 / Innovation First",
            desc: "As AI pioneers, we push boundaries to deliver cutting-edge solutions that keep you ahead.",
            img: blub
        }, {
            value: "Value 2 / Trust & Transparency",
            desc: "Our Transparent Solutions Provide Accurate, Actionable Data You Can Trust.",
            img: handshake
        }, {
            value: "Value 3 / Customer-Centric Approach",
            desc: "Your Success Drives Us. Our Solutions Are Tailored For Optimal Results And Ease.",
            img: users
        }, {
            value: "Value 4 / Future-Ready Solutions",
            desc: "We Solve Today's Challenges And Prepare You For Tomorrow's Evolving World.",
            img: robo
        }
    ];

    return (
        <div className="px-4 sm:px-10 lg:px-20 py-10">

            {/* Title Section */}
            <div className="relative inline-block">
                <div className="bg-gradient-to-r from-[#5b4a59] via-[#b9adb6] to-[#5b4a59] p-[1.5px] rounded-full">
                    <div className="bg-[#262532] flex flex-row py-[4px] sm:py-2 px-4 sm:px-2 sm:pr-4 rounded-full font-bold text-white text-[12px] sm:text-[14px] text-center">
                        <img src={reddot} alt="" />
                        What we stand for
                    </div>
                </div>
            </div>

            <div className="font-semibold text-3xl sm:text-5xl lg:text-[60px] text-white py-6 text-center lg:text-left">
                Our Values Fuel Innovation
            </div>

            <div className="font-semibold text-[14px] sm:text-[16px] text-[#b3b7ba] lg:w-[42%] text-center lg:text-left">
                We believe in delivering AI technology that’s not only powerful but also ethical, reliable, and built for lasting impact.
            </div>

            {/* Values Section */}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 flex-wrap mt-10">
                {
                    values.map((value, index) => (
                        <div key={index} className="p-4 bg-[#101d26] w-full sm:w-[48%] border-[1px] border-[#3e484f] rounded-lg">
                            <div className="border-b-2 border-[#3e484f] flex justify-between items-center py-2">
                                <div className="text-white">
                                    {value.value}
                                </div>
                                <div className="">
                                    <img src={value.img} alt="value icon" className="w-6 h-6" />
                                </div>
                            </div>
                            <div className="py-2 text-[#b3b7ba]">
                                {value.desc}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
