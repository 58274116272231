import React from "react";

import blub from "../../assets/home/bulb.svg";
import handshake from "../../assets/home/handshake.svg";
import users from "../../assets/home/users.svg";
import robo from "../../assets/home/robo.svg";
import reddot from "../../assets/home/reddot.svg";

import rightarrow from '../../assets/home/rightarrow.svg'

import newsbg from "../../assets/home/newsbg.svg"; // Import your background image

export default function News() {
    return (
        <div
            className="px-4 sm:px-10 lg:px-20 py-10 bg-cover bg-center"
            style={{ backgroundImage: `url(${newsbg})` }} // Apply the background image
        >
            <div className="flex justify-center">
                <div className="w-[94%] flex-col">
                    {/* Title Section */}
                    <div className="flex justify-center sm:justify-center  lg:justify-start ">
                        <div className="relative">
                            <div className="bg-gradient-to-r from-[#5b4a59] via-[#b9adb6] to-[#5b4a59] p-[1.5px] rounded-full">
                                <div className="bg-[#262532] flex flex-row py-[4px] sm:py-2 px-4 sm:px-2 sm:pr-4 rounded-full font-bold text-white text-[12px] sm:text-[14px] text-center">
                                    <img src={reddot} alt="" />
                                    News
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start">
                        <div className="font-semibold text-3xl sm:text-5xl lg:text-[60px] text-white py-8 text-center lg:text-left">
                            Industry Recognition Spotlight
                        </div>
                    </div>
                    <div className="flex justify-start">
                        <div className="font-semibold text-[14px] sm:text-[16px] text-[#b3b7ba] lg:w-[46%]">
                            Explore how Arhasi.AI is shaping the future of AI, as featured by
                            leading publications and industry leaders.
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className=" flex justify-start">
                <div className=" py-12 sm:w-[98%] lg:w-[48%] lg:ml-[3%]">
                    <div className=" text-[#FF6666] text-[12px] font-semibold">
                        FEATURED ARTICLE
                    </div>
                    <div className=" font-semibold text-[18px] py-6">
                        Arhasi.AI Ranked as a Top AI Innovator for 2024
                    </div>
                    <div className=" font-semibold text-[16px] text-[#b3b7ba]">
                        For its cutting-edge approach and impact on multiple industries, Arhasi.AI has been recognized as one of the top 10 AI inno..More
                    </div>
                    <div className=" flex flex-row justify-between py-6 items-center">
                        <div className=" text-[#b3b7ba]">

                            <div className="">
                                Source
                            </div>
                            <div className=" text-white">
                                Featured in Forbes, Oct 2024
                            </div>
                        </div>
                        <div className=" border-gray-400 mr-8 border-[1px] rounded-[6px] cursor-pointer backdrop-blur-lg bg-white/5 h-min p-[6px] flex justify-center align-middle items-center">
                            <img src={rightarrow} alt="" />
                        </div>
                    </div>
                </div>
            </div> */}
            {/*  */}
            <div className=" flex justify-start ml-[3%]">
                <div className=" py-12 sm:w-[98%] lg:w-[48%]">
                    <div className=" text-[#FF6666] text-[12px] font-semibold">
                        {'Latest Press Release'.toUpperCase()}
                    </div>
                    <div className=" font-semibold text-[18px] py-6">
                        Partnering with GlobalTech for AI in Healthcare
                    </div>
                    <div className=" font-semibold text-[16px] text-[#b3b7ba]">
                        This strategic partnership will revolutionize healthcare operations through innovative AI solutions that enhanc..More                    </div>
                    <div className=" flex flex-row justify-between py-6 items-center">
                        <div className=" text-[#b3b7ba]">

                            <div className="">
                                Date
                            </div>
                            <div className=" text-white">
                                Oct 2024
                            </div>
                        </div>
                        <div className=" border-gray-400 mr-8 border-[1px] rounded-[6px] cursor-pointer backdrop-blur-lg bg-white/5 h-min p-[6px] flex justify-center align-middle items-center">
                            <img src={rightarrow} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className=" flex justify-end">
                <div className=" py-12 sm:w-[98%] lg:w-[48%]">
                    <div className=" text-[#FF6666] text-[12px] font-semibold">
                        {'Media Mention'.toUpperCase()}
                    </div>
                    <div className=" font-semibold text-[18px] py-6">
                        AI Transforming Customer Experiences: Arhasi.AI
                    </div>
                    <div className=" font-semibold text-[16px] text-[#b3b7ba]">
                        Arhasi.AI’s solutions are setting new benchmarks for how companies engage with their customers, using AI to cre..More                    </div>
                    <div className=" flex flex-row justify-between py-6 items-center">
                        <div className=" text-[#b3b7ba]">

                            <div className="">
                                Source
                            </div>
                            <div className=" text-white">
                                TechCrunch, Sept 2024
                            </div>
                        </div>
                        <div className=" border-gray-400 mr-8 border-[1px] rounded-[6px] cursor-pointer backdrop-blur-lg bg-white/5 h-min p-[6px] flex justify-center align-middle items-center">
                            <img src={rightarrow} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
