import React from "react";

interface Launch {
  show?: false;
  dispBg?: true;
}

const LaunchingSoon: React.FC<Launch> = ({ show, dispBg }) => {
  return (
    <>
      {show ? (
        <>
          <div
            className={`flex items-center justify-center ${
              dispBg ? "bg-black" : ""
            }`}
          >
            <div className="text-center">
              <h1 className="text-white text-lg font-bold relative">
                <span className="relative z-10">Launching Soon</span>
                <span className="absolute inset-0 text-red-500 blur-[5px]">
                  Launching Soon
                </span>
              </h1>
              <p className="mt-6 text-gray-300 text-[16px]">
                Stay tuned for something amazing!
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`flex items-center justify-center ${
              dispBg ? "bg-black" : ""
            }`}
          >
            <div className="text-center">
              <h1 className="text-white text-4xl md:text-6xl font-bold relative">
                <span className="relative z-10">Launching Soon</span>
                <span className="absolute inset-0 text-red-500 blur-[5px]">
                  Launching Soon
                </span>
              </h1>
              <p className="mt-6 text-gray-300 text-md">
                Stay tuned for something amazing!
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LaunchingSoon;
