import React from 'react'

import sfull from '../../assets/home/sfull.svg'
import shalf from '../../assets/home/shalf.svg'
import lwcso from '../../assets/home/lwcso.svg'
import ceocloud from '../../assets/home/ceocloud.svg'
import reddot from '../../assets/home/reddot.svg'

export default function CustTestimonials() {

    const values = [
        {
            role: "CSO, LifeWorks",
            desc: '"Coupling Arhasi’s AI frameworks and tools is the accelerator pedal for rapid AI adoption. The LifeWorks AI employee assistant offers our employees a superior alternative for policy and procedure guidance."',
            stars: [sfull, sfull, sfull, sfull, shalf],
            name: 'Wendy Varnell',
            img: lwcso,
            rating: `{ 4.5 Rating }`
        },
        //  {
        //     role: "CEO at Cloudbench",
        //     desc: `"With our partners Google and Arhasi, we're poised to assist startups and midmarket enterprises in navigating the intricate landscapes of technology and data, thereby enabling them to innovate more effectively."`,
        //     stars: [sfull, sfull, sfull, sfull, sfull],
        //     name: 'Tom Richer',
        //     img: ceocloud,
        //     rating: `{ 5 Rating }`

        // },
    ];

    return (
        <div>
            <div className=" flex justify-center">
                <div className=" w-[80%]  flex-col">
                    {/* Title Section */}
                    <div className="flex justify-center">
                        <div className="relative ">
                            <div className="bg-gradient-to-r from-[#5b4a59] via-[#b9adb6] to-[#5b4a59] p-[1.5px] rounded-full">
                                <div className="bg-[#262532] flex flex-row py-[4px] sm:py-2 px-4 sm:px-2 sm:pr-3 rounded-full font-bold text-white text-[12px] sm:text-[14px] text-center">
                                    <img src={reddot} alt="" />
                                    Social Proof
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">

                        <div className="font-semibold text-3xl sm:text-5xl lg:text-[60px] text-white py-10 text-center lg:text-left">
                            Proven Success Stories
                        </div>
                    </div>
                    <div className=" flex justify-center">

                        <div className="font-semibold text-[14px] sm:text-[16px] text-[#b3b7ba] lg:w-[46%] text-center">
                            See how Arhasi.AI has empowered clients to achieve remarkable results.
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-8 flex-wrap mt-10 px-4 sm:px-4 md:px-28">
                {
                    values.map((value, index) => (
                        <div key={index} className="p-4 bg-[#101d26]  sm:w-[98%] md:w-[48%] border-[1px] border-[#3e484f] rounded-lg">
                            <div className="border-b-2 border-[#3e484f] flex justify-between items-center py-2">
                                <div className=" flex flex-row gap-1">
                                    {
                                        value.stars.map((star) => {
                                            return (

                                                <img src={star} alt="value icon" />
                                            )
                                        })
                                    }
                                </div>

                                <div className="text-[#b7bbbd] text-[16px] font-semibold">
                                    {value.rating}
                                </div>

                            </div>
                            <div className="py-2 text-white text-[18px] font-semibold">
                                {value.desc}
                            </div>

                            <div className=" flex flex-row gap-3 items-center align-middle ">
                                <div className="">
                                    <img src={value.img} alt="role" />
                                </div>
                                <div className=" flex flex-col ">
                                    <div className=" text-[14px] text-white">
                                        {value.name}                                    </div>
                                    <div className=" text-p-md text-[#b7babd]">
                                        {value.role}                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}
